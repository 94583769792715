<template lang="pug">
  .tab-main-container
    .headline 記録詳細
    div.p-3(v-if="message") {{ this.message }}
    .container-fluid.wrapper(v-if="!message")
      .route-table-wrapper
        .route-table
          table.table.table-sm 
            tr
              td.th 岩場
              td {{rocky_name}}
            tr
              td.th 岩
              td {{rock_name}}
            tr
              td.th ルート
              td {{route_name}}
            tr
              td.th 難易度
              td {{level}}
      .complete-user-count-wrapper
        .complete-user-count
          |完登した他のユーザー ： {{completeClimbCount}}人
      .user-climb-record-wrapper
        .user-climb-record
          .user-climb-record-inner(v-if="record")
            table.table.table-bordered
              thead
                tr
                  th(colspan="3" scope="col") 登攀記録
              tbody
                tr(v-for="(row, index) in record")
                  td.align-middle {{ row.climbDate.slice(0, 10).replace(/-/g, '/') }}
                  td.align-middle 
                    .deital.p-1
                      .type.mt-2 {{ClimbKind[row.climbKind]}}
                      .link.small
                        router-link.small.btn.btn-primary(:to="{ name: 'record_edit', query: { id: row.id, ref: 'route_detail'}}" v-if="$route.params.userId == $user.userId")
                          i.edit.fa.fa-pencil-alt
    
</template>

<script>
import axios from 'axios'

const climbKind = {
  1: 'オンサイト',
  2: 'フラッシュ',
  3: '完登',
  4: 'チャレンジ中',
}

export default {
  //データオブジェクト
  data() {
    return {
      record: null,
      rocky_name: null,
      rock_name: null,
      route_name: null,
      completeClimbCount: null,
      level: null,
      rockyId: null,
      isLogin: this.$user.isLogin,
      ClimbKind: climbKind,
      routeId: null,
      message: '',
    }
  },
  created() {
    this.rockyId = this.$route.query.rockyId
    this.routeId = this.$route.query.routeId
    this.createdProcess()
  },
  //メソッド
  methods: {
    createdProcess() {
      this.getRecord()
      this.getCompleteCount()
    },
    getRecord() {
      //記録の取得
      axios.get(process.env.VUE_APP_API_GATEWAY + '/api-record', {
        params: {
          "rockyId": this.rockyId,
          "routeId": this.routeId,
          "targetUserId": this.$route.params.userId,
          "type": 'routeDetail',
        },
        headers: {
          'Authorization': this.$user.isLogin ? this.$user.idToken : 'no-login'
        }
      })
      .then(res => {
        this.record = res.data
        this.rocky_name = res.data[0].rockyName
        this.rock_name = res.data[0].rockName
        this.route_name = res.data[0].routeName
        this.level = res.data[0].gradeName
      })
      .catch(err => {
        this.message = '情報の取得に失敗しました'
      })
    },
    getCompleteCount(){
      //他ユーザーの登攀件数を取得する
      const jsonUrl = process.env.VUE_APP_API_GATEWAY + '/api-search-record/other-users-complete-count'
      axios.get(jsonUrl, {
        params: {
          "routeId": this.routeId,
          "targetUserId": this.$route.params.userId
        },
        headers: {
          'Authorization': this.$user.isLogin ? this.$user.idToken : 'no-login'
        }
      })
      .then(res => {
        this.completeClimbCount = res.data['count']
      })
      .catch(err => {
        this.message = '情報の取得に失敗しました'
      })
    },
  }
}
</script>


<style lang="stylus" scoped>
.wrapper
  padding 0px
  min-height 90vh
.route-table-wrapper
  padding 1rem 30px 0
  .route-table
    .table
      td.th
        font-weight bold
.complete-user-count-wrapper
  font-size 1.2rem
  padding-left 30px
.user-climb-record-wrapper
  color #FFF
  padding 1rem 15px
//ユーザーの登攀記録
.user-climb-record
	width 100%
.user-climb-record-inner 
	padding 0.5rem
	background-color #171D32
.table-bordered 
	background-color FFF
	border solid  3px #171D32
	tr 
		td 
			background-color #FFF
			border solid  3px #171D32
	thead 
		background-color #171D32
		color #FFF
		font-size 1rem
	small
		color #0D47A1
		padding-left 50px
table th
  padding 0
.table td
  padding 3px 10px
  font-size 0.8rem
  .deital
    display flex
    justify-content space-between

//PC表示
@media (min-width 1170px)
  .route-table-wrapper 
    .table td
      padding 10px
</style>